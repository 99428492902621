<template>
  <div
    class="modal fade exportServicesModal show modal-overlay"
    style="display: block"
    tabindex="-1"
    @click.self="$emit('resetModal', '')"
  >
    <the-modal :modal-id="'exportZoomModal'">
      <template #header>
        <img src="@/assets/images/ic_zoom-on.svg" alt="zoom" />
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click="$emit('resetModal', '')"
          style=""
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </template>

      <div class="modal-body">
        <p>
          ZoomミーティングルームのURLと表示名を入力してZoomを開始してください。
        </p>
        <form>
          <div class="form-group row">
            <label for="meetingURL" class="col-sm-12 col-form-label text-center"
              >ミーティングルームURL</label
            >
            <div class="col-sm-12">
              <input
                type="text"
                class="form-control-plaintext"
                id="meetingURL"
                v-model="roomId"
                placeholder=""
              />
            </div>
          </div>
          <!-- <div class="form-group row">
            
            <div class="col-sm-6">
                <label for="meetingId" class="col-sm-12 col-form-label text-center"
                style="padding-left: 0 !important; padding-right: 0 !important;">
                Meeting Room ID</label
              >
              <input
                type="text"
                class="form-control-plaintext"
                id="meetingId"
                v-model="meetingId"
                placeholder="xxxxx"
              />
            </div>
            <div class="col-sm-6">
              <label for="meetingPassword" class="col-sm-12 col-form-label text-center"
              style="padding-left: 0 !important; padding-right: 0 !important;">
              Meeting Password</label>
              <input
                type="text"
                class="form-control-plaintext"
                id="meetingPassword"
                v-model="meetingPassword"
                placeholder="xxxxx"
              />
            </div>
          </div> -->
          <div class="form-group row">
            <label
              for="displayName"
              class="col-sm-12 col-form-label text-center"
              >表示名</label
            >
            <div class="col-sm-12">
              <input
                v-model="displayName"
                type="text"
                class="form-control"
                id="displayName"
                placeholder="AI翻訳"
              />
            </div>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              name="agree"
              id="agree"
              v-model="isChecked"
            />
            <label class="form-check-label" for="agree">
              AI翻訳<a href="https://honyaku.ai/terms-of-use.html" target="_blank" rel="noopener noreferrer">利用規約とプライバシーポリシー</a>に同意します。
            </label>
          </div>
        </form>
      </div>

      <template #footer>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          data-toggle="modal"
          data-target="#recording"
          @click="gotoZoom"
        >
          Zoomに入室し翻訳を開始
        </button>
      </template>
    </the-modal>
  </div>
</template>

<script>
import TheModal from "./the-modal.vue";
import { getCurrentUserIdToken } from '../../firebase'
import querystring from 'querystring'

export default {
  components: {
    TheModal,
  },
  data: () => ({
    roomId: "",
    meetingId: "",
    meetingPassword: "",
    displayName: "",
    isChecked: false,
    zoomUrl: "/zoom-meeting/",
  }),
  methods: {
    async gotoZoom() {
      //
      if (!this.isChecked) return;
      if (this.roomId == "") return;

      //roomId = https://us05web.zoom.us/j/81037204202?pwd=UnppZkhDUUgxKzZiWHRvU05HazZhZz09

      const p1 = this.roomId.split("zoom.us/j/");
      const s = p1[1].split("?pwd=");

      this.meetingId = s[0];
      this.meetingPassword = s[1];
      const token = await getCurrentUserIdToken()
      window.open(
        this.zoomUrl + 
          "index.html?" + querystring.stringify({ token }) +"&email=&role=0&lang=en-US&mn=" +
          this.meetingId +
          "&pwd=" +
          this.meetingPassword +
          "&name=" +
          btoa(this.displayName),
        "_blank"
      );
      this.$emit("resetModal", "");
    },
  },
};
</script>
