import { IDP_NAME } from './env/publicConfig.json'
import { auth } from './firebase'

export const signin = async (token) => {
  const payload = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
  const altUid = payload.sub
  if (!altUid) throw 'cannot get sub from jwt'
  const query = new URLSearchParams({ productCode: 'honyaku' })
  const res = await fetch(`https://us-central1-${IDP_NAME}.cloudfunctions.net/api/sso/${altUid}/customToken?${query}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    method: 'GET'
  })
  const data = await res.json()
  if (data.error) throw data.error
  await auth.signInWithCustomToken(data.token)
}