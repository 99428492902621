<template>
  <div
    class="modal fade exportServicesModal show modal-overlay"
    style="display: block"
    tabindex="-1"
  >
    <the-modal :modal-id="'exportZoomModal'">
      

      <div class="modal-body">
        <p style="font-size:26px;font-weight: bold;">
          拡張機能をインストールしてください。
          <br>

        </p>
        <p style="font-size:17px;">
          AI翻訳はGoogle Chromeの拡
          <br>張機能を使用します。下のボタ
          <br>ンから拡張機能をインストー 
          <br>ルしてください。
          <br> 
          <br>拡張機能インストール後はこ
          <br>のパネルは表示されません。

        </p>
      </div>

      <template #footer>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          data-toggle="modal"
          data-target="#recording"
          @click="gotoInstall"
        >
          拡張機能をインストール
        </button>
      </template>
    </the-modal>
  </div>
</template>

<script>
import TheModal from "./the-modal.vue";

export default {
  components: {
    TheModal
  },
  data: () => ({
    roomId: "",
    password: "",
    isChecked: false
  }),
  methods: {
    
    gotoInstall() {
      //window.location.href = "https://chrome.google.com/webstore/detail/bulk-url-opener-extension/hgenngnjgfkdggambccohomebieocekm";
      //let routeData = this.$router.resolve({name: 'routeName', query: {data: "someData"}});
      window.open("https://chrome.google.com/webstore/detail/ai%E7%BF%BB%E8%A8%B3-by-%E3%82%AA%E3%83%AB%E3%83%84/kloliancchmfilfklalpociilkfgfchn/related", '_blank');
    }
  }
};
</script>

