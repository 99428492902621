<template>
  <div class="beforeTranslate">
    <ai-navigation :displayName="displayName" :email="email"></ai-navigation>

    <!-- Topscreen -->
    <main id="topscreen" class="topscreen">
      <div id="contents">
        <lang-container :class="{ openSettingPanel: !isSettingsHided }" />

        <setting-panel v-model="modal" @toggle-settings="isSettingsHided = $event"></setting-panel>
        <!-- /END #settingPanel -->
      </div>
      <!-- /END #contents -->

      <div class="topscreen_overlay"></div>
    </main>
    <!-- /END #topscreen -->

    <zoom-modal v-if="modal === 'zoom'" @resetModal="modal = $event" />
    <teams-modal v-if="modal === 'teams'" @resetModal="modal = $event" />
    <meet-modal v-if="modal === 'meet'" @resetModal="modal = $event" />
    <whereby-modal v-if="modal === 'whereby'" @resetModal="modal = $event" />
    <hangouts-modal v-if="modal === 'hangouts'" @resetModal="modal = $event" />
    <skype-modal v-if="modal === 'skype'" @resetModal="modal = $event" />
    <extension-install id="install" v-if="showInstall" @resetModal="modal = $event" />
  </div>
</template>

<script>
import LangContainer from "@/components/lang-container.vue";
import AiNavigation from "@/components/ai-navigation.vue";
import SettingPanel from "@/components/setting-panel.vue";
import ZoomModal from "@/components/modals/zoom-modal.vue";
import TeamsModal from "@/components/modals/teams-modal.vue";
import HangoutsModal from "@/components/modals/hangouts-modal.vue";
import ExtensionInstall from "@/components/modals/extension-install.vue";
import WherebyModal from "@/components/modals/whereby-modal.vue";
import MeetModal from "@/components/modals/meet-modal.vue";
import SkypeModal from "@/components/modals/skype-modal.vue";
import { getCurrentUser } from '@/firebase.js'
import { signin } from '@/altid.js'
import { IDP_URL } from '@/env/publicConfig.json'

export default {
  components: {
    LangContainer,
    AiNavigation,
    SettingPanel,
    ZoomModal,
    TeamsModal,
    HangoutsModal,
    WherebyModal,
    MeetModal,
    SkypeModal,
    ExtensionInstall,
  },
  data: () => ({
    modal: "",
    isSettingsHided: false,
    showInstall: false,
    displayName: "",
    email: "",
  }),
  watch: {
    modal() {},
    isSettingsHided() {},
  },
  methods: {
    openInstall() {
      const key = require("./extensionid.json");
      const extensionid = key.extensionid;
      this.detectExtension(extensionid); //'bklhnippjlkciileljfpiepjdjclhnno');
    },
    detectExtension(extensionId) {
      var img;
      img = new Image();
      img.src = "chrome-extension://" + extensionId + "/icon16.png";
      img.onload = function () {
        localStorage.setItem("stateInstall", false);
      };
      img.onerror = function () {
        localStorage.setItem("stateInstall", true);
      };
    },
    showModal() {
      var is = localStorage.getItem("stateInstall");
      if (is === "true") {
        this.showInstall = localStorage.getItem("stateInstall");
      }
    },
    changeInput(lang){
      console.log("Input Lang===", lang);
    }
  },
  async mounted() {
    const { token } = this.$route.query;
    if (token) {
      await signin(token)
      window.location.href = "/";
    }
    const user = await getCurrentUser();
    if (!user || !user.uid) {
      window.location.href = `${IDP_URL}/login?product_code=honyaku`
    }
    this.displayName = user.displayName
    this.email = user.email
    localStorage.setItem("stateInstall", null);
    this.openInstall();
    this.interval = setInterval(() => this.showModal(), 1000);
  }
};
</script>

<style>
.modal-overlay {
    background: rgba(0, 0, 0, 0.3);
}
@font-face {
  font-family: "ElvishFont";
  src: local("JunctionRegular"),
   url(./assets/fonts/tnganbi.woff) format("truetype");
}
</style>
